// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../../..';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../..';
import { ButtonOutline, ButtonPrimary } from 'texkit/dist/components/Buttons';

//Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

const TPWDSpeedbumpModal = () => {
  const dispatch = useDispatch();

  const handleExitClick = () => {
    window.onunload = window.onbeforeunload = null;
    dispatch(closeModal('TPWDSpeedbumpModal'));
    dispatch(push('/dashboard'));
  };

  return (
    <Modal name="TPWDSpeedbumpModal" className="renewal-speedbump-modal" disableOnClickOutside disableCloseButton>
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="TPWD_Speedbump_Title" />
      </h2>
      <div className="modal-content">
        <p className="lead" style={{ maxWidth: '570px', margin: '0 auto', fontSize: '16px' }}>
          <Trans file="Modals" id="TPWD_Speedbump_Body" />
        </p>
      </div>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={handleExitClick}>
            <Trans file="Labels" id="exit" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <ButtonOutline onClick={() => dispatch(closeModal('TPWDSpeedbumpModal'))}>
            <Trans file="Labels" id="Continue" />
          </ButtonOutline>
        </FormActionsItem>
      </FormActions>
    </Modal>
  );
};

export default TPWDSpeedbumpModal;
