
// Vendors
import React, { useContext, useEffect, useState } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
// Components
import { Trans, Form } from '../../..';
import { ButtonPrimary, Button, ButtonOutline } from 'texkit/dist/components/Buttons';
import PaymentSelectCardList from '../../PaymentSelectCardList/PaymentSelectCardList';
// Actions
import { goBack } from 'connected-react-router';
import { submitPaymentFromAccountList } from '../../../../reducers/payment/paymentActions';
import { PaymentContext } from '../../Payment';
import { clearAlertInfo } from '../../../../reducers/alert/alertActions';
// Selectors
import { selectPaymentState } from '../../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../../reducers/route/routeSelectors';
const PaymentType = ({ paymentState: { paymentInfo = {} } }) => {
  if (paymentInfo.feeInfo) {
    return (
      <h3 className="payment-select-account-title">
        <Trans file="Labels" id="RenewalFee" fallback="Renewal fee" />
      </h3>
    );
  }
  return <p hidden>This hidden content makes the heading accessible.</p>;
};
PaymentType.propTypes = {
  paymentState: PropTypes.shape({
    paymentInfo: PropTypes.object,
  }).isRequired,
};
const SelectPaymentAccount = () => {
  const { t : translate} = useTranslation('tpwd');
  const dispatch = useDispatch();
  const paymentState = useSelector(selectPaymentState);
  const paymentContext = useContext(PaymentContext);
  const currentPath = useSelector(selectPathname);
  const showWarning = useState(false);
  const onSubmit = (values, formikActions) => {
    dispatch(
      submitPaymentFromAccountList(values, formikActions, paymentContext, translate)
    );
  };
  // Clear alert when page changes
  useEffect(() => () => {
    dispatch(clearAlertInfo());
  });

  return (
    <div className="payment-select-account">
      <h3 className="payment-select-account-title">
        <PaymentType paymentState={paymentState} />
      </h3>
      <h3>
        <Trans file="Payment" id="CreditCard" fallback="Credit Card" />
      </h3>

      <Form
        className="payment-select-account-form"
        formikConfig={{
          onSubmit,
          initialValues: { selectPayment: '' },
          validationSchema:
            currentPath === '/dmvrr/payment' || currentPath === '/dpslr/payment' || currentPath === '/tdlrlr/payment' || currentPath === '/tpwdas/payment' 
              ? yup.object().shape({
                cvv: yup
                  .string()
                  .min(3)
                  .max(4)
                  .required(),
              })
              : {},
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
          <>
            <PaymentSelectCardList formikProps={formikProps} showWarning={showWarning} />
            <FormError />
            <FormActions>
              <FormActionsItem>
                <ButtonPrimary
                  type="submit"
                  isLoading={formikProps.isSubmitting}
                  disabled={!formikProps.isValid || formikProps.isSubmitting || (
                    formikProps.values.hasOwnProperty('cvv') && (formikProps.values.cvv.length < 3 || formikProps.values.cvv == '')
                  )}
                  loadingContent={<Trans file="Labels" id="Submitting" />}
                >
                  <Trans file="Payment" id="PayNow" fallback="Pay Now" />
                </ButtonPrimary>
              </FormActionsItem>
              <FormActionsItem>
                <ButtonOutline
                  onClick={() => dispatch(goBack())}
                  disabled={formikProps.isSubmitting}
                >
                  <Trans file="Labels" id="Back" fallback="Back" />
                </ButtonOutline>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      />
    </div>
  );
};
export default SelectPaymentAccount;
