export function selectAgencySelected({
  tpwd: { selected: { link: { profile: { account = [] } = {} } = {} } = {} },
}) {
  return account;
}

export function selectAdditionalIdCardSelected({
  tpwd: { additionalIdCardSelected = false },
}) {
  return additionalIdCardSelected;
}

export function selectAdditionalIdCardQuantity({
  tpwd: { additionalIdCardQuantity = 0 },
}) {
  return additionalIdCardQuantity;
}

export function selectedCatalogItemTypes({
  tpwd: { selectedCatalogItemTypes = [] },
}) {
  return selectedCatalogItemTypes;
}

export function selectUpdatedFeeDetails({
  tpwd: { updatedFeeDetails = [] },
}) {
  return updatedFeeDetails;
}