// Vendors
import React from 'react';
import { useDispatch , useSelector} from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

// Selectors
import {
  selectAgencySelected,
} from '../../../../../../reducers/agency/tpwd/tpwdSelectors';
import { selectModalData } from '../../../../../../reducers/modal/modalSelectors';

// Styles
import '../tpwd-modal.scss';

const TPWD_Transaction_Submitted_Modal = () => {

  const { errorCode=[] } = (useSelector(selectModalData) || {});
  const dispatch = useDispatch();
  const account = useSelector(selectAgencySelected);

 const handleExitClick = () => {
        window.onunload = window.onbeforeunload = null;
        dispatch(closeModal('TPWD_Transaction_Submitted_Modal'));
        dispatch(push('/dashboard'));
  };
  const [
    {
      assetType = '',
    } = {},
  ] = account;
  const assetTypeLC = assetType.toLowerCase();
  return (
    <Modal name="TPWD_Transaction_Submitted_Modal" className="tpwdas-modal" disableOnClickOutside disableCloseButton>
      <h2 className="h1 modal-title-general">
        <Trans file="tpwd" id="tpwd_tx_recently_submitted" />
      </h2>
      <p className="modal-description-general">
        <Trans file="tpwd" id={errorCode.includes('MGMW_EX_3906')?"tpwd_dup_tx_submitted_desc":"tpwd_tx_submitted_desc"} args={[assetTypeLC]}/>
      </p>

      <ButtonPrimary
        className="modal-close-button-general"
        onClick={handleExitClick}
      >
        <Trans file="Labels" id="Dashboard" />
      </ButtonPrimary>
    </Modal>
  );
};

export default TPWD_Transaction_Submitted_Modal;
