//@flow

// Vendors
import React, { useRef } from 'react';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { Accordion } from '../../../../..';

// Styles
import './tpwd-FAQ-modal.scss';

const TPWDFAQModal = () => {
  const modal = useRef();
  const modalMobile = useRef();

  return (
    <>
      <Modal name="TPWDFAQModal" className="tpwd-faq-modal">
        <div className="modal-content-wrapper" ref={modalMobile}>
          <h2 className="h1 modal-title">
            <Trans file="tpwd" id="tpwd_FAQ_modal_title" />
          </h2>
          <div ref={modal} className="modal-inner-body">
            <Accordion
              key={'tpwd_FAQ_1'}
              title={<Trans file="tpwd" id="tpwd_FAQ_1" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_1_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_2'}
              title={<Trans file="tpwd" id="tpwd_FAQ_2" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_2_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_3'}
              title={<Trans file="tpwd" id="tpwd_FAQ_3" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_3_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_4'}
              title={<Trans file="tpwd" id="tpwd_FAQ_4" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_4_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_5'}
              title={<Trans file="tpwd" id="tpwd_FAQ_5" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_5_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_6'}
              title={<Trans file="tpwd" id="tpwd_FAQ_6" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_6_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_7'}
              title={<Trans file="tpwd" id="tpwd_FAQ_7" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_7_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_8'}
              title={<Trans file="tpwd" id="tpwd_FAQ_8" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_8_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_9'}
              title={<Trans file="tpwd" id="tpwd_FAQ_9" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_9_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_10'}
              title={<Trans file="tpwd" id="tpwd_FAQ_10" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_10_Desc" />}
            />
            <Accordion
              key={'tpwd_FAQ_11'}
              title={<Trans file="tpwd" id="tpwd_FAQ_11" />}
              content={<Trans file="tpwd" id="tpwd_FAQ_11_Desc" />}
            />
          </div>
        </div>
        <div className="btn-wrapper">
          <ButtonPrimary
            className="modal-button initial"
            onClick={() => {
              if (modal && modal.current) modal.current.scrollTop = 0;
            }}
            icon="more"
          >
            <Icon name="chevron-up" />
            <Trans file="Labels" id="BackToTop" />
          </ButtonPrimary>
          <ButtonPrimary
            className="modal-button-mobile initial"
            onClick={() => {
              if (modalMobile && modalMobile.current)
                modalMobile.current.scrollTop = 0;
            }}
            icon="more"
          >
            <Icon name="chevron-up" />
            <Trans file="Labels" id="BackToTop" />
          </ButtonPrimary>
        </div>
      </Modal>
    </>
  );
};

export default TPWDFAQModal;
