// Vendors
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import { Trans } from '../../..';
import { TextButton } from 'texkit/dist/components/Buttons';
// Actions
import { openModal } from '../../../../reducers/modal/modalActions';

const TPWDFooter = ({
  isTpwdAgency
}) => {
  const dispatch = useDispatch();

  return isTpwdAgency ? (
    <li className="footer-nav-list-item">
      <TextButton as="a" onClick={() => dispatch(openModal('TPWDFAQModal'))}>
        <Trans file="Common" id="TPWD_FAQ" />
      </TextButton>
    </li>
  ) : null;
};


TPWDFooter.propTypes = {
  isTpwdAgency: PropTypes.bool
};

export default memo(withRouter(TPWDFooter));
