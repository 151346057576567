//@flow

// Vendors
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// Components
import { Container, Trans, LanguageSwitcher } from '..';
import DPSFooter from './apps/DPS/DPSFooter';
import TPWDFooter from './apps/TPWD/TPWDFooter';

// Actions

// Selectors
import { selectAgencyByRouteParam } from '../../reducers/agency/agencySelectors';

// Assets
import StateOfTexasLogo from '../../assets/txt-footer-logo.svg';

// Styles
import './footer.scss';

const Footer = ({ className, agency: { agencySlug = '' } }: Props) => {
  const { t } = useTranslation('Footer');
  const helpSupportLink = t('HelpSupportLink');
  const faqLink = t('FAQLink');
  const currentYear = new Date().getFullYear();
  const isDpsAgency = agencySlug === 'dps';
  const pathArr = window.location.pathname.split('/');
  const isTpwdAgency = pathArr.find(path => (path === 'tpwd'|| path === 'tpwdas'));
  const agencyFAQ = isDpsAgency || isTpwdAgency;

  return (
    <footer className={classnames('footer', className)} role="contentinfo">
      <Container className={classnames({ dps: agencyFAQ })}>
        <div className="footer-copyright">
          <div className="footer-logo-copyright">
            <a
              href="https://texas.gov/"
              target="_blank"
              rel="noopener noreferrer"
              alt="State of Texas Logo"
            >
              <img
                src={StateOfTexasLogo}
                className="state-of-texas-logo"
                alt={
                  <Trans
                    file="Footer"
                    id="StateOfTexasLogoAlt"
                    fallback="State of Texas Logo"
                  />
                }
              />
            </a>
            <div className="copyright-text">
              <Trans
                file="Footer"
                id="Copyright"
                fallback={`Copyright © ${currentYear} State of Texas. All rights reserved.`}
              />
            </div>
          </div>

          <LanguageSwitcher dropdownProps={{ menuPosition: 'top-left' }} />
        </div>

        <nav
          className={classnames('footer-nav', { dps: agencyFAQ })}
          role="navigation"
        >
          <ul className={classnames('footer-nav-list', { dps: agencyFAQ })}>
            <li className="footer-nav-list-item">
              <Link target={'_blank'} to="/terms-conditions">
                <Trans
                  file="Common"
                  id="TermsAndConditions"
                  fallback="Terms & Conditions"
                />
              </Link>
            </li>
            <DPSFooter />
            <TPWDFooter isTpwdAgency={isTpwdAgency}/>
          </ul>
          <ul className={classnames('footer-nav-list', { dps: agencyFAQ })}>
            <li className="footer-nav-list-item">
              <a href={faqLink} target="_blank" rel="noreferrer">
                <Trans
                  file="Footer"
                  id="FrequentlyAskedQuestions"
                  fallback="TxT FAQs"
                />
              </a>
            </li>
            <li className="footer-nav-list-item">
              <a href={helpSupportLink} target="_blank" rel="noreferrer">
                <Trans
                  file="Footer"
                  id="GetSupport"
                  fallback="Technical support"
                />
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
};

const mapStateToProps = (state, props): Object => ({
  agency: selectAgencyByRouteParam(state, props),
});

Footer.propTypes = {
  className: PropTypes.string,
  agency: PropTypes.object,
  agencySlug: PropTypes.string,
};

export default memo(withRouter(connect(mapStateToProps, null)(Footer)));
