/* eslint-disable react/display-name */

// Vendor
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Components
import { Trans, FormError, LicenseDetailsModal } from '../../..';
import {
  TxButton,
  TxButtonMode,
  TxInput,
  TxLoadingText,
  TxInputMask,
} from 'texkit-ui';
import { TxFormGroup, TxFormActions } from 'texkit-ui/forms';
import DMVSpecialCharModal from '../../Modals/DMVSpecialCharModal/DMVSpecialCharModal';
import TPWDTXNumberModal from '../../Modals/TPWDTXNumberModal/TPWDTXNumberModal';
import { SSNFormInput } from './Inputs';

import { Formik } from 'formik';

// Helpers
import { getAppDataByCodes } from '../../../../lib/helpers';
import { createLinkServiceFormValidationSchema } from '../../../../lib/validation';
import * as lookupHints from './LinkServiceLookupHints';
import { openModal } from '../../../../reducers/modal/modalActions';

// Types
import { formikInjectedPropsTypes } from '../../../../lib/validation/propTypes/formikPropTypes';
import LicenseDetailModal from './LicenseDetailModal';

//Using a button causes Formik to fire
export const DMVSpecialModal = () => {
  const [isOpen, setOpen] = useState(false);
  const openModalFunc = e => {
    e.preventDefault();
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          openModalFunc(e);
        }}
      >
        <Trans
          file="Modals"
          id="DMVSpecialCharModal_Link"
          fallback="How to enter license plate symbols"
        />
      </a>
      <DMVSpecialCharModal open={isOpen} close={closeModal} />
    </>
  );
};

export const TPWDSpecialModal = () => {
  const dispatch = useDispatch();
  return (
    <>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          dispatch(openModal('TPWDTXNumberModal'));
        }}
      >
        <Trans
          file="Modals"
          id="TPWDSpecialModal_Link"
          fallback="How to find your TX number"
        />
      </a>
      <TPWDTXNumberModal />
    </>
  );
};

export const AppParamsPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attr: PropTypes.object.isRequired,
  openModal: PropTypes.func,
});

const getHint = id => {
  const hint = lookupHints[id] ? lookupHints[id] : '';
  return hint && hint.content ? (
    <Trans file={hint.content.file} id={hint.content.id} />
  ) : (
    ''
  );
};

const toInputUppercase = e => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

const formatDOB = e => {
  let value = e.target.value;
  let formattedDate = value.replaceAll("\/", "");
  if (formattedDate.length === 8) {
    const concatDate = formattedDate.slice(0, 2) + '\/' + formattedDate.slice(2);
    formattedDate = concatDate.slice(0, 5) + '\/' + concatDate.slice(5);
    e.target.value = formattedDate;
  } else
    e.target.value = value;
};

function applyFormik(name, { handleBlur, touched, errors }) {
  return {
    name,
    key: name,
    onBlur: handleBlur,
    errorMessage:
      touched[name] && errors[name] ? (
        <Trans file="Errors" id={errors[name].id} />
      ) : null,
  };
}

const INPUTS = {
  DEFAULT: ({ form, name, attr, formikProps }) => (
    <TxInput
      {...applyFormik(name, formikProps)}
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      label={
        <Trans
          file="Labels"
          id={form && form.labels[name] && form.labels[name].id}
        />
      }
      required={attr.required === 'true'}
      max={attr.max}
      maxLength={attr.max}
      hintMessage={form && form.labels[name] && getHint(form.labels[name].id)}
    />
  ),

  dlIDNumber: ({ form, name, attr, formikProps }) => (
    <TxInputMask
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      mask="99999999"
    >
      <TxInput
        {...applyFormik(name, formikProps)}
        label={
          <Trans
            file="Labels"
            id={form && form.labels[name] && form.labels[name].id}
          />
        }
        required={attr.required === 'true'}
        max={attr.max}
        maxLength={attr.max}
        hintMessage={form && form.labels[name] && getHint(form.labels[name].id)}
      />
    </TxInputMask>
  ),
  auditNumber: ({ form, name, attr, formikProps }) => (
    <>
      {' '}
      <TxInputMask
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        mask="99999999999999999999"
      >
        <TxInput
          {...applyFormik(name, formikProps)}
          label={
            <Trans
              file="Labels"
              id={form && form.labels[name] && form.labels[name].id}
            />
          }
          required={attr.required === 'true'}
          max={attr.max}
          maxLength={attr.max}
          hintMessage={
            form && form.labels[name] && getHint(form.labels[name].id)
          }
        />
      </TxInputMask>
      <LicenseDetailModal />
    </>
  ),
  // TODO: Determine which id for SSN field is correct
  last4ssn: ({ form, name, attr, formikProps }) => (
    <TxInputMask
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      mask="9999"
    >
      <TxInput
        {...applyFormik(name, formikProps)}
        label={
          <Trans
            file="Labels"
            id={form && form.labels[name] && form.labels[name].id}
          />
        }
        required={attr.required === 'true'}
        max={attr.max}
        maxLength={attr.max}
        hintMessage={getHint(form && form.labels[name] && form.labels[name].id)}
      />
    </TxInputMask>
  ),
  dob: ({ form, name, attr, formikProps, agencyCode }) =>
  (<TxInputMask
    onChange={formikProps.handleChange}
    mask="99/99/9999"
  >
    <TxInput
      onInput={formatDOB}
      {...applyFormik(name, formikProps)}
      label={
        <Trans
          file="Labels"
          id={form && form.labels[name] && form.labels[name].id}
        />
      }
      required={attr.required === 'true'}
      max={attr.max}
      maxLength={attr.max}
      placeholder={agencyCode === '802' ? 'MM/DD/YYYY' : ''}
    />
  </TxInputMask >),
  lastFourOfVIN: ({ form, name, attr, formikProps }) => {
    return (
      <TxInputMask
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        mask="9999"
      >
        <TxInput
          {...applyFormik(name, formikProps)}
          label={
            <Trans
              file="Labels"
              id={form && form.labels[name] && form.labels[name].id}
            />
          }
          required={attr.required === 'true'}
          max={attr.max}
          maxLength={attr.max}
          {...(!formikProps.isValid && {
            hintMessage: getHint(
              form && form.labels[name] && form.labels[name].id
            ),
          })}
        />
      </TxInputMask>
    );
  },
  plateNumber: ({ form, name, attr, formikProps }) => (
    <>
      <TxInput
        {...applyFormik(name, formikProps)}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        label={
          <Trans
            file="Labels"
            id={form && form.labels[name] && form.labels[name].id}
          />
        }
        onInput={toInputUppercase}
        required={attr.required === 'true'}
        max={attr.max}
        maxLength={attr.max}
        hintMessage={getHint(form && form.labels[name] && form.labels[name].id)}
      />
      <DMVSpecialModal />
    </>
  ),
  ssn: ({ form, name, attr, formikProps }) => {
    return (
      <TxInputMask
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        mask="999-99-9999"
      >
        <SSNFormInput
          {...applyFormik(name, formikProps)}
          onChange={formikProps.handleChange}
          onPaste={evt => evt.preventDefault()}
          value={formikProps.values[name]}
          label={
            <Trans
              file="Labels"
              id={form && form.labels[name] && form.labels[name].id}
            />
          }
          required={attr.required === 'true'}
          max={attr.max}
          maxLength={11}
          hintMessage={
            form && form.labels[name] && getHint(form.labels[name].id)
          }
        />
      </TxInputMask>
    );
  },
  txNumber: ({ form, name, attr, formikProps }) => (
    <>
      <TPWDSpecialModal />
      <TxInput
        {...applyFormik(name, formikProps)}
        value={formikProps.values[name]}
        onChange={formikProps.handleChange}
        label={
          <Trans
            file="Labels"
            id={form && form.labels[name] && form.labels[name].id}
          />
        }
        onInput={toInputUppercase}
        placeholder="1234XX"
        required={attr.required === 'true'}
        max={attr.max}
        maxLength={attr.max}
        hintMessage={getHint(form && form.labels[name] && form.labels[name].id)}
      />
    </>
  ),
  lastName: ({ form, name, attr, formikProps, agencyCode }) => (
    <TxInput
      {...applyFormik(name, formikProps)}
      value={formikProps.values[name]}
      onChange={formikProps.handleChange}
      onKeyDown={e => {
        if (!e.key.match(/^[a-zA-Z]*$|\s|-/)) {
          e.preventDefault();
        }
      }}
      onPaste={e => {
        const regex = /^[a-zA-Z-\s]*$/;
        const key = e.clipboardData.getData('text');
        if( !regex.test(key)) {
          e.preventDefault();
        }
      }}
      label={
        <Trans
          file="Labels"
          id={form && form.labels[name] && form.labels[name].id}
        />
      }
      placeholder={agencyCode === '802' ? 'Doe' : ''}
      required={attr.required === 'true'}
      max={attr.max}
      maxLength={attr.max}
    />
  ),
};

const INPUTSPropTypes = {
  form: PropTypes.any.isRequired,
  name: PropTypes.string,
  attr: PropTypes.any.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  openModal: PropTypes.any,
  agencyCode: PropTypes.string,
};

INPUTS.DEFAULT.propTypes = INPUTSPropTypes;
INPUTS.dlIDNumber.propTypes = INPUTSPropTypes;
INPUTS.last4ssn.propTypes = INPUTSPropTypes;
INPUTS.dob.propTypes = INPUTSPropTypes;
INPUTS.lastFourOfVIN.propTypes = INPUTSPropTypes;
INPUTS.plateNumber.propTypes = INPUTSPropTypes;
INPUTS.ssn.propTypes = INPUTSPropTypes;
INPUTS.txNumber.propTypes = INPUTSPropTypes;
INPUTS.lastName.propTypes = INPUTSPropTypes;

const INPUT_REDUCER = (form, { name, attr = {} }, formikProps, agencyCode) =>
  INPUTS[name]
    ? INPUTS[name]({ form, name, attr, formikProps, agencyCode })
    : INPUTS.DEFAULT({ form, name, attr, formikProps });

const CustomContent = ({ agencyCode }) => {
  if (agencyCode === '452')
    return (
      <p className="link-service-lookup-form-custom">
        <Trans
          file="LinkService"
          id="LookupCustomContent_TDLR"
          fallback="Please make sure your last name matches your TDLR license including any suffix or special characters."
        />
      </p>
    );
  if (agencyCode === '608')
    return (
      <p className="link-service-lookup-form-custom">
        <Trans file="LinkService" id="LookupCustomContent_DMVRR" />
      </p>
    );

  return null;
};

const order = {
  dlIDNumber: 1,
  txNumber: 1,
  auditNumber: 2,
  lastName: 2,
  dob: 3,
  last4ssn: 4,
  ssn: 5,
};

CustomContent.propTypes = {
  agencyCode: PropTypes.string,
};

const LinkServiceLookupForm = ({
  agency,
  app,
  appParams,
  reLinkData,
  onSubmit,
  onCancel,
  submitText = <Trans file="Labels" id="Next" />,
}) => (
  <>
    <Formik
      onSubmit={onSubmit.bind(this)}
      initialValues={appParams.reduce((acc, param) => {
        acc[param.name] = '';
        acc['lastFourOfVIN'] = reLinkData ? reLinkData : '';
        acc['assetType'] = 'BOAT';
        return acc;
      }, {})}
      validationSchema={createLinkServiceFormValidationSchema(
        agency,
        app,
        appParams
      )}
    >
      {formikProps => {
        const { isSubmitting, isValid, handleSubmit } = formikProps;
        return (
          <form onSubmit={handleSubmit} className="pt-5">
            <TxFormGroup>
              {appParams
                .map(item => {
                  return { ...item, order: order[item.name] || 99 };
                }).filter((item) => item.name !== 'assetType')
                .sort((a, b) => a.order - b.order)
                .map(params => {
                  return INPUT_REDUCER(
                    getAppDataByCodes(agency.agencyCode, app.appCode)
                      .linkService.form,
                    params,
                    formikProps,
                    agency.agencyCode
                  );
                  const linkServiceForm = getAppDataByCodes(
                    agency.agencyCode,
                    app.appCode
                  ).linkService.form;
                  return INPUT_REDUCER(linkServiceForm, params, formikProps);
                })}
            </TxFormGroup>
            <TxFormGroup>
              <FormError formikProps={formikProps} />
            </TxFormGroup>
            <TxFormActions>
              <TxButton
                mode={TxButtonMode.Primary}
                type="submit"
                disabled={!isValid}
              >
                {isSubmitting ? (
                  <TxLoadingText>
                    <Trans file="Labels" id="Submitting" />
                  </TxLoadingText>
                ) : (
                  <>{submitText}</>
                )}
              </TxButton>
              {onCancel && (
                <TxButton
                  mode={TxButtonMode.Secondary}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  <Trans file="Labels" id="Back" />
                </TxButton>
              )}
            </TxFormActions>
            <LicenseDetailsModal />
          </form>
        );
      }}
    </Formik>
  </>
);

LinkServiceLookupForm.propTypes = {
  agency: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  appParams: PropTypes.arrayOf(AppParamsPropTypes).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.node,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  reLinkData: PropTypes.string,
};

export default LinkServiceLookupForm;
