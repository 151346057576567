//@flow

// Vendors
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { TxButton, TxButtonMode, TxLoadingText } from 'texkit-ui/components';
import { useTranslation } from 'react-i18next';

// Components
import {
  Modal,
  FormActions,
  FormActionsItem,
  Trans,
  ServiceIcon,
  ServiceIconText,
} from '../..';

import SummaryCard from '../../Cards/SummaryCard/SummaryCard';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  unlinkAppLink,
  removeLinkFromState,
} from '../../../../reducers/agency/agencyActions';
import {
  pushMessageBanner,
  setAutoDismissMessageBannerTimer,
} from '../../../../reducers/messageBanner/messageBannerActions';

// Helpers
import { capitalize, toSentenceCase } from '../../../../lib/helpers';

// Styles
import './unlink-service-modal.scss';

// Types
type Props = {
  modal: Object,
  agencies: Object,
  closeModal: Function,
  unlinkAppLink: Function,
  removeLinkFromState: Function,
};

// Switch to adjusted SCSS if on iOS device
var userAgent = window.navigator.userAgent.toLowerCase(),
  ios = /iphone|ipod|ipad/.test(userAgent);

var mainClassName = ios ? 'unlink-service-modal-ios' : 'unlink-service-modal';

const UnlinkServiceModal = ({
  unlinkAppLink,
  closeModal,
  removeLinkFromState,
  modal = {},
}: Props) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const { t } = useTranslation('tpwd');
  if (
    modal.activeModal !== 'UnlinkServiceModal' ||
    !modal.data ||
    !modal.data.link ||
    !modal.data.agency
  )
    return null;

  const {
    data: { link, agency },
  } = modal;
  const {
    profile: {
      account: [accounts],
    },
  } = link;
  const { assetType = '' } = accounts;
  const assetTypeLC = assetType.toLowerCase();
  const hasLinks = agency.linkUserKeys && agency.linkUserKeys.length > 1;

  function handleRemoveLinkClick() {
    setSubmitting(true);

    const messageIdTime = Date.now();

    const successBanner =
      hasLinks && assetType
        ? `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner`
        : hasLinks
          ? `${agency.agencySlug}_unlinkNotificationMessageBanner`
          : agency.agencySlug !== 'dps'
            ? `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner`
            : link.profile.account.length > 0 &&
              link.profile.account[0].licenseType === 'DL'
              ? `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner_DL`
              : link.profile.account[0].licenseType === 'CDL'
                ? `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner_CDL`
                : `${agency.agencySlug}_NoMoreAgencyNotificationsMessageBanner_ID`;

    const linkKey =
      agency.agencySlug === 'dps' && link.profile.account.length > 0
        ? link.profile.account[0].licenseType
        : modal.data.licenseNumber;

    unlinkAppLink(link, linkKey)
      .then(() => {
        dispatch(
          pushMessageBanner([
            {
              messageId: messageIdTime.toString(),
              textEnglish: `{"type": "Modals", "id": "${successBanner}", "args":"${assetTypeLC}"}`,
              textSpanish: `{"type": "Modals", "id": "${successBanner}", "args":"${assetTypeLC}"}`,
              startTime: '2021-01-01 18:35:00.0',
              endTime: '2040-06-30 12:05:00.0',
              priority: '4',
              status: 'R',
              author: 'USER',
              valid: true,
              dismissable: true,
            },
          ])
        );
        setTimeout(() => {
          removeLinkFromState(link);
          if (!hasLinks) dispatch(push('/dashboard'));
          closeModal('UnlinkServiceModal');
        }, 200);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
        setAutoDismissMessageBannerTimer(messageIdTime);
      })
      .catch(() => {
        dispatch(
          pushMessageBanner([
            {
              messageId: messageIdTime.toString(),
              textEnglish: `{"type": "Modals", "id": "${agency.agencySlug}_cantUnlink"}`,
              textSpanish: `{"type": "Modals", "id": "${agency.agencySlug}_cantUnlink"}`,
              startTime: '2021-01-01 18:35:00.0',
              endTime: '2040-06-30 12:05:00.0',
              priority: '1',
              status: 'R',
              author: 'USER',
              valid: true,
              dismissable: true,
            },
          ])
        );
        setSubmitting(false);
        closeModal('UnlinkServiceModal');
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 300);
      });
  }

  return (
    <Modal
      name="UnlinkServiceModal"
      className={`${mainClassName} ${agency.agencySlug}-modal`}
      onClose={() => setSubmitting(false)}
      disableOnClickOutside={true}
      disableCloseButton={false}
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id={`${agency.agencySlug}_AreYouSureYouWantToRemoveThisLink`}
          args={[assetType.toLowerCase()]}
        />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Trans
            file="Modals"
            agency={agency}
            id={
              hasLinks
                ? `${agency.agencySlug}_unlinkNotification`
                : `${agency.agencySlug}_NoMoreAgencyNotifications`
            }
            args={[assetType]}
          />
        </p>
        <div className={`unlink-service-modal-list`}>
          {link.profile.account.map(account => {
            if (
              account.licenseNumber &&
              modal.data.licenseNumber &&
              account.licenseNumber === modal.data.licenseNumber
            )
              return (
                <>
                  <SummaryCard
                    key={account.licenseNumber}
                    className="card-flat"
                    outlined={false}
                    graphic={<ServiceIcon type="professional-license" />}
                    horizontalList={true}
                  >
                    <SummaryCard.List
                      items={[
                        {
                          label: (
                            <Trans
                              file="Labels"
                              id="LicenseType"
                              fallback="LicenseType"
                            />
                          ),
                          value: account.licenseType,
                        },
                        {
                          label: (
                            <Trans
                              file="Labels"
                              id="LicenseNumber"
                              fallback="LicenseNumber"
                            />
                          ),
                          value: account.licenseNumber,
                        },
                      ]}
                    />
                  </SummaryCard>
                </>
              );
            else if (account.vin)
              return (
                <>
                  <SummaryCard
                    key={account.vin}
                    className="card-flat"
                    outlined={false}
                  >
                    <div className="flex-item">
                      <div className="flex-row-wrap">
                        <div className="flex-item license-plate-icon">
                          <ServiceIconText
                            className="agency-linked-services-item-icon-text flex-item"
                            type={`license-plate`}
                            text={account.plateNumber}
                            isUnlinkVehicleModal={true}
                          />
                        </div>
                        <div className="flex-item vehicle-details">
                          <SummaryCard.List
                            items={[
                              {
                                label: (
                                  <Trans
                                    file="Labels"
                                    id="vehicleMake"
                                    fallback="vehicleMake"
                                  />
                                ),
                                value: capitalize(account.vehicleMake),
                              },
                              {
                                label: (
                                  <Trans
                                    file="Labels"
                                    id="modelYear"
                                    fallback="modelYear"
                                  />
                                ),
                                value: account.vehicleModelYear,
                              },
                              {
                                label: (
                                  <Trans
                                    file="Labels"
                                    id="vin"
                                    fallback="vin"
                                  />
                                ),
                                value: account.vin,
                              },
                              {
                                label: (
                                  <Trans
                                    file="Labels"
                                    id="registrationExpiry"
                                    fallback="registrationExpiry"
                                  />
                                ),
                                value:
                                  account.registrationExpiryMonth +
                                  '/' +
                                  account.registrationExpiryYear,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </SummaryCard>
                </>
              );
            else if (account.dlIdNumber) {
              const summaryList =
                account.licenseType === 'DL' || account.licenseType === 'CDL'
                  ? [
                    {
                      label: (
                        <Trans file="Labels" id="Name" fallback="Name" />
                      ),
                      value: `${account.personalInformation.firstName
                        ? account.personalInformation.firstName
                        : ''
                        }
                        ${account.personalInformation.middleName
                          ? account.personalInformation.middleName
                          : ''
                        }
                      ${account.personalInformation.lastName
                          ? account.personalInformation.lastName
                          : ''
                        }
                        ${account.personalInformation.suffix
                          ? account.personalInformation.suffix
                          : ''
                        }`,
                    },
                    {
                      label: (
                        <Trans file="Labels" id="Class" fallback="Class" />
                      ),
                      value: account.licenseClass,
                    },
                    {
                      label: (
                        <Trans
                          file="Labels"
                          id="Expiration"
                          fallback="Expiration"
                        />
                      ),
                      value: moment(account.expirationDate).format(
                        'MM/DD/YYYY'
                      ),
                    },
                  ]
                  : [
                    {
                      label: (
                        <Trans file="Labels" id="Name" fallback="Name" />
                      ),
                      value: `${account.personalInformation.firstName}
                        ${account.personalInformation.middleName
                          ? account.personalInformation.middleName
                          : ''
                        }
                      ${account.personalInformation.lastName}
                        ${account.personalInformation.suffix
                          ? account.personalInformation.suffix
                          : ''
                        }`,
                    },
                    {
                      label: (
                        <Trans
                          file="Labels"
                          id="Expiration_Date"
                          fallback="Expiration_Date"
                        />
                      ),
                      value: moment(account.expirationDate).format(
                        'MM/DD/YYYY'
                      ),
                    },
                  ];

              return (
                <>
                  <SummaryCard
                    key={account.vin}
                    className="card-flat"
                    outlined={false}
                  >
                    <div className="flex-item">
                      <div className="flex-row-wrap">
                        <div className="flex-item license-plate-icon">
                          <ServiceIcon
                            className="agency-linked-services-item-icon-text flex-item"
                            type={`driver-license`}
                          />
                          <div className="flex-item service-icon text">
                            {account.licenseType === 'DL' ||
                              account.licenseType === 'CDL' ? (
                              <Trans
                                file={agency.agencySlug}
                                id={`${agency.agencySlug}_Driver_License`}
                              />
                            ) : (
                              <Trans
                                file={agency.agencySlug}
                                id={`${agency.agencySlug}_ID_Card`}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex-item vehicle-details">
                          <SummaryCard.List items={summaryList} />
                        </div>
                      </div>
                    </div>
                  </SummaryCard>
                </>
              );
            } else if (account.txNumber)
              return (
                <>
                  <SummaryCard
                    key={account.txNumber}
                    className="summary-card-tpwd card-flat"
                    outlined={false}
                    graphic={
                      <ServiceIconText
                        // isUnlinkVehicleModal={true}
                        className="agency-linked-services-item-icon-text"
                        type={`boat-plate`}
                        text={`TX ${account.txNumber.substring(0, 4) +
                          ' ' +
                          account.txNumber.substring(4)}`}
                      />
                    }
                    horizontalList={false}
                  >
                    <div className="vehicle-details vehicle-details-asset">
                      <div className="vehicle-details-tpwd">
                        <SummaryCard.List
                          fullWidth
                          items={[
                            {
                              label: <Trans file="Labels" id="assetType" />,
                              value: t(`tpwdas_${toSentenceCase(assetType)}`),
                            },
                            {
                              label: <Trans file="Labels" id="assetMake" />,
                              value: account.make,
                            },
                            {
                              label: (
                                <Trans
                                  file="Labels"
                                  id="assetRegistrationExpiry"
                                />
                              ),
                              value: moment(account.expirationDate).format(
                                'MM/DD/YYYY'
                              ),
                            },
                          ]}
                        />
                        <SummaryCard.List
                          className={`${agency.agencySlug}`}
                          fullWidth
                          items={[
                            {
                              label: (
                                <Trans file="Labels" id="assetModelYear" />
                              ),
                              value: account.modelYear,
                            },
                            {
                              label: <Trans file="Labels" id="assetLength" />,
                              value: account.length,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </SummaryCard>
                </>
              );
            return '';
          })}
        </div>

        <FormActions align="center">
          <FormActionsItem>
            <TxButton
              className="ButtonDanger"
              onClick={handleRemoveLinkClick}
              mode={TxButtonMode.Danger}
            >
              {isSubmitting ? (
                <TxLoadingText>
                  <Trans file="Labels" id="Removing" fallback="Removing" />
                </TxLoadingText>
              ) : (
                <Trans file="Labels" id="Remove" />
              )}
            </TxButton>
          </FormActionsItem>

          <FormActionsItem>
            <TxButton
              className="ButtonPrimary"
              onClick={() => closeModal('UnlinkServiceModal')}
              mode={TxButtonMode.Secondary}
              disabled={isSubmitting}
            >
              <Trans file="Labels" id="Cancel" fallback="Cancel" />
            </TxButton>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators(
    { closeModal, unlinkAppLink, removeLinkFromState },
    dispatch
  );
const mapStateToProps = ({ modal }): Object => ({ modal });

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkServiceModal);
