// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem, Trans } from '../../../components';
import { TxButton, TxButtonMode } from 'texkit-ui/components';

// Actions
import { getLookUpDataAndRenewTPWD } from '../../../reducers/agency/tpwd/tpwdActions';
import { APP_TRANSACTION_IDS} from '../../../reducers/app/appActions';

// Types
import { AppParamsPropTypes } from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';

const LinkServiceSuccess = ({ link }) => {
  const dispatch = useDispatch();
  const { agency,userKey, appId, app, linkData: { eligibleForRenewal = false, assetType = "" } } = link;

  const goToTPWDDashboard = () => {
    dispatch(push(`/agency/${agency.agencySlug}`));
  };

  const handleRenewClick = () => {
    dispatch(
      getLookUpDataAndRenewTPWD({
        appId: app.appId,
        userKeys: app.linkUserKeys.slice(app.linkUserKeys.length - 1),
        transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
      })
    );
  };


  return (
    <>
      <h1 className="h2">
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_SuccessTitle`}
        />
      </h1>
      <>
        <p className="link-service-lookup-form-custom">
          <Trans file="LinkService" id={eligibleForRenewal ? "SuccessCustomContent_DMV_Renew" : "SuccessCustomContent_TPWD"} />
        </p>
        {eligibleForRenewal ? <FormActions>
          <FormActionsItem>
            <ButtonPrimary onClick={handleRenewClick}>
              <Trans file="Labels" id={`StartRenewal`} />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <TxButton
              mode={TxButtonMode.Secondary}
              onClick={goToTPWDDashboard}
            >
              <Trans file="Labels" id={`${app.appSlug}_ViewTxTDashboard`} args={[
                assetType.toLowerCase()
              ]} />
            </TxButton>
          </FormActionsItem>
        </FormActions> :
          <FormActions>
            <FormActionsItem>
              <ButtonPrimary onClick={goToTPWDDashboard}>
                <Trans file="Labels" id={`${app.appSlug}_ViewTxTDashboard`} args={[
                  assetType.toLowerCase()
                ]} />
              </ButtonPrimary>
            </FormActionsItem>
          </FormActions>}
      </>
    </>
  );
};

LinkServiceSuccess.propTypes = {
  link: PropTypes.shape({
    params: PropTypes.arrayOf(AppParamsPropTypes),
    agency: PropTypes.object,
    app: PropTypes.object,
    linkData: PropTypes.object,
    success: PropTypes.object,
    relinkData: PropTypes.string,
  }).isRequired,
};

export default LinkServiceSuccess;
