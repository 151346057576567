// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import * as Transitions from '../Transitions';

// Styles
import './stepper.scss';
/**
 * @visibleName Default
 */
const Stepper = ({
  steps = [],
  activeStep,
  transition = 'SlideTransition',
  onStepChange,
  onStepChanged,
  className,
  closeModal,
}) => {
  if (!steps || !steps.length) return null;

  const Transition = Transitions[transition];

  return (
    <div className={classnames('stepper', className)}>
      {steps.map(step => {
        const { id: stepId, component: Step, data } = step;
        return (
          <Transition
            key={stepId}
            className="stepper-step"
            in={activeStep === stepId}
            onEnter={onStepChange}
            onEntered={onStepChanged}
          >
            <Step
              steps={steps}
              stepId={stepId}
              data={data}
              closeModal={closeModal}
            />
          </Transition>
        );
      })}
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      data: PropTypes.object,
    })
  ).isRequired,
  activeStep: PropTypes.string.isRequired,
  className: PropTypes.string,
  transition: PropTypes.string,
  onStepChange: PropTypes.func,
  onStepChanged: PropTypes.func,
  closeModal: PropTypes.func,
};

export default Stepper;
