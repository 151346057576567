// Vendors
import { push } from 'connected-react-router';

// requests
import { lookupAppLinkRequest } from '../../../backend/requests';
//actions
import { APP_TRANSACTION_IDS, clearAppData, storeBasicInfo } from '../../app/appActions';
//helpers
import { to , toTitleCase, toSentenceCase} from '../../../lib/helpers';

//constants
export const TPWD_TPWDAS_CLEAR_DATA = '[TPWD] TPWDAS - Clear Data';
export const TPWD_MANAGE_ASSET = '[TPWD] Manage Asset';
export const TPWD_ADDITIONAL_ID_CARD_SELECTED = 'ADDITIONAL ID CARDS';
export const TPWD_ADDITIONAL_ID_CARD_QUANTITY = 'ADDITIONAL ID CARD QUANTITY';
export const TPWD_SELECTED_CATALOG_ITEMS = 'SELECTED CATALOG ITEMS';
export const TPWD_UPDATE_FEE_DETAILS = '[TPWD] UPDATE FEE DETAILS';
export const STEPS_CONFIG ='STEPS_CONFIG';
export const STEPS_CONFIG_DELETE = 'STEPS_CONFIG_DELETE';
export const STEPS_CONFIG_UPDATE = 'STEPS_CONFIG_UPDATE';
export const STEPS_CONFIG_ACTIVATE = 'STEPS_CONFIG_ACTIVATE';
export const catalogDescriptionMap = {
  RR01: "tpwd_renewal_RR01",
  RR02: "tpwd_renewal_RR02",
  RR03: "tpwd_renewal_RR03",
  RR04: "tpwd_renewal_RR04",
  RR05: "tpwd_renewal_RR05",
  RR06: "tpwd_renewal_RR06",
  RECRDB: "tpwd_RECRDB",
  RDECAL: "tpwd_RDECAL",
  REPCRD: "tpwd_REPCRD",
  RCARD: "tpwd_RCARD",
  SPDON: "tpwd_donation",
  HANDEF: "tpwd_processing_fee"
};
export const TPWD_UPDATE_PAYMENT_WAS_MADE = 'FALSE';

export const manageTPWDAsset = data => {
  return async function (dispatch) {
    await dispatch(storeBasicInfo(data));
    await dispatch(storeSelected(data));
    dispatch(push('/tpwdas'));
  };
};

export function storeSelected(data) {
  return {
    type: TPWD_MANAGE_ASSET,
    payload: data,
  };
}

export const clearTPWDASData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: TPWD_TPWDAS_CLEAR_DATA });
};

export function setAdditionalIdCardSelected(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_SELECTED,
    payload: data,
  };
}

export function storeAdditionalIdCardQuantity(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_QUANTITY,
    payload: data,
  };
}

export function setSelectedCatalogItemTypes(data) {
  return {
    type: TPWD_SELECTED_CATALOG_ITEMS,
    payload: data,
  };
}

export const updateFeeDetails = payload => {
  return {
    type: TPWD_UPDATE_FEE_DETAILS,
    payload,
  };
};

export const updatePaymentWasMade = (status = false) => {
  return {
    type: TPWD_UPDATE_PAYMENT_WAS_MADE,
    status: status,
  };
};

export const getTPWDFeeDetails = feeDetailsRequest => {
  return async function (dispatch, getStore) {
    const store = getStore();
    const { userKey, appId, selectedCatalogItemTypes, additionalIdCardQuantity } = store.tpwd.selected;

    const [{ body }, err] = await to(
      dispatch(
        lookupAppLinkRequest({
          transactionInfo: feeDetailsRequest,
          appId: appId,
          userKeys: [userKey],
          transactionId: APP_TRANSACTION_IDS.FEE_CALCULATION,
        })
      )
    );
    if (err) return Promise.reject(err);
    dispatch(updateFeeDetails({ ...body.data }));
    return Promise.resolve();
  };
};

export const formatTPWDPayment = (translate) => {
  return async function (dispatch, getStore) {
  
    const { tpwd, user } = getStore();
    const { profile: contDetails={} } = user;
    const { selectedCatalogItemTypes, additionalIdCardQuantity, updatedFeeDetails = {} } = tpwd;
    const { userKey, appId, agencyId, vin, link: { profile: { account={} } ={}} } = tpwd.selected;
    const [accountDetails] = account;
    const { primaryOwnerAddress={}, primaryOwnerFirstName, primaryOwnerLastName } = accountDetails;
    const userKeys = [userKey];
    const contactDetails = {
      firstName: contDetails.fName,
      middleName: '',
      lastName: contDetails.lName,
      primaryOwnerFirstName: primaryOwnerFirstName ? toSentenceCase(primaryOwnerFirstName) : '',
      primaryOwnerLastName: toSentenceCase(primaryOwnerLastName),
      suffix: '',
      entity: contDetails.businessName ? contDetails.businessName : '',
      customerType: contDetails.addressType,
      email: contDetails.userEmail,
      phone: contDetails.phoneNum,

      mailingAddress: {
        address1: primaryOwnerAddress.address1 ? toTitleCase(primaryOwnerAddress.address1) : null,
        address2: primaryOwnerAddress.address2 ? toTitleCase(primaryOwnerAddress.address2): null,
        city: primaryOwnerAddress.city? toTitleCase(primaryOwnerAddress.city):null,
        postalCode: primaryOwnerAddress.postalCode,
        stateProvince: primaryOwnerAddress.stateProvince,
      },
    };

    const assetDetails = {
      assetType: accountDetails.assetType || 'N/A',
      txNumber: accountDetails.txNumber || 'N/A',
      modelYear: accountDetails.modelYear || 'N/A',
      make: accountDetails.make || 'N/A',
      hullType: accountDetails.hullMaterial|| 'N/A',
      length: accountDetails.length || 'N/A',
      expirationDate: accountDetails.expirationDate|| 'N/A',
      additionalIdCardsQuantity: additionalIdCardQuantity,
      selectedCatalogItemTypes: selectedCatalogItemTypes.map((catalog) => catalog.code),
    };

    const feeDetails = [];
    //update feedetails based on the selected services
    updatedFeeDetails.paymentCatalogItems.forEach((fee) => {
      const feeDetail = {};
      feeDetail.feeCode = fee.code;
      if(fee.code === 'RECRDB')
        feeDetail.feeDescription = translate(catalogDescriptionMap['REPCRD']);
      else if(fee.code === 'RCARD')
        feeDetail.feeDescription = translate(catalogDescriptionMap['RCARD'], {1:additionalIdCardQuantity});
      else 
      feeDetail.feeDescription = translate(catalogDescriptionMap[fee.code]);
      feeDetail.feeAmount =  parseFloat(fee.unitPrice || 0).toFixed(2);
      if(fee.code === 'SPDON' && updatedFeeDetails.donationSelected)
      {
      feeDetails.push(feeDetail);}
      else if(fee.code !== 'SPDON') {
        feeDetails.push(feeDetail);}
    })
    // adding handling fee in feeDetails for email
    feeDetails.push({
      'code': 'HANDEF',
      'feeDescription': translate(catalogDescriptionMap['HANDEF']),
      'feeAmount': updatedFeeDetails.donationSelected ?
      parseFloat(updatedFeeDetails.totalConvenienceFeeWithDonation || 0).toFixed(2)
      : parseFloat(updatedFeeDetails.boatConvenienceFee ||0).toFixed(2)
    })
    const boatRenewalFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code !== 'SPDON').reduce((accumulator, currentValue) => {
      return accumulator + currentValue.unitPrice
    }, 0)
    const parksDonationFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code === 'SPDON')[0].unitPrice;

    //create the final transaction object
    const transactionInfo = {
      ...assetDetails,
      contactDetails: contactDetails,
      transactionAmounts: {
        boatRenewalFee: parseFloat(boatRenewalFee ||0).toFixed(2),
        boatRenewalOption: true,
        feeDetails,
        subTotal: updatedFeeDetails.donationSelected ?
          parseFloat((updatedFeeDetails.totalFeeWithDonation) || 0).toFixed(2) : parseFloat((updatedFeeDetails.totalFeeWithOutDonation) ||0).toFixed(2),
        handlingFeeOption: true,
        handlingFee: updatedFeeDetails.donationSelected ?
          parseFloat(updatedFeeDetails.totalConvenienceFeeWithDonation || 0).toFixed(2)
          : parseFloat(updatedFeeDetails.boatConvenienceFee || 0).toFixed(2),
        parksDonationFee: updatedFeeDetails.donationSelected ? parksDonationFee : undefined,
        parksDonationOption: updatedFeeDetails.donationSelected ? true : undefined,
        donationConvenienceFee: parseFloat(updatedFeeDetails.donationConvenienceFee ||0).toFixed(2),
        boatConvenienceFee: parseFloat(updatedFeeDetails.boatConvenienceFee ||0).toFixed(2),
      }
    };

    return {
      userKeys,
      appId,
      agencyId,
      transactionId: APP_TRANSACTION_IDS.SUBMIT_CREATE_PAYMENT,
      transactionInfo,
    };
  };
};

export const setStepsConfig = payload => {
  return {
    type: STEPS_CONFIG,
    payload,
  };
};

export const setStepsConfigUpdate = (id) => {
  return {
    type: STEPS_CONFIG_UPDATE,
    id,
  };
};

export const setStepsConfigReload = (id) => {
  return {
    type: STEPS_CONFIG_ACTIVATE,
    id,
  };
};
export function getLookUpDataAndRenewTPWD({
  appId,
  userKeys,
  transactionId,
}) {
  return async function(dispatch) {
    const requestBody = {
      appId,
      userKeys,
      transactionId,
    };
    const [{ body ={}}, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );
    if(body.data && body.data.profiles  && body.data.profiles.length>0)
    {
    dispatch(
      manageTPWDAsset({
        link: {profile: (body.data || {}).profiles[0]},
        userKey: ((body.data || {}).profiles[0] || {}).userKey,
        appId,
        agencyId: '13',
      })
    );
  }
    if (err) return Promise.reject(err);
    return Promise.resolve(body.data);
  };
}
