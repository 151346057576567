//@flow

// Vendors
import React from 'react';

// Components
import {
  ServiceDownModal,
  ServerErrorModal,
  TermsAndConditionsModal,
  VehicleNotFoundModal,
  LinkDLIDModal,
  LockoutModal,
  DPSFAQModal,
  DPS_EMERGENCY_FAQModal,
  DPS_Renewal_FAQModal,
  DPS_REPLACEMENT_FAQModal,
  RenewalSpeedbumpModal,
  TPWDSpeedbumpModal,
  PaymentFormErrorModal,
  DPS_USER_LOCKOUT_MODAL,
  TPWDFAQModal,
} from '..';

const GlobalModals = () => (
  <>
    <ServiceDownModal />
    <ServerErrorModal />
    <TermsAndConditionsModal />
    <VehicleNotFoundModal />
    <LinkDLIDModal />
    <LockoutModal />
    <DPS_USER_LOCKOUT_MODAL />

    <DPSFAQModal />
    <DPS_EMERGENCY_FAQModal />
    <DPS_Renewal_FAQModal />
    <DPS_REPLACEMENT_FAQModal />
    <RenewalSpeedbumpModal />
    <TPWDSpeedbumpModal/>
    <PaymentFormErrorModal />
    <TPWDFAQModal />
    {/* Add more global modals here */}
  </>
);

export default GlobalModals;
